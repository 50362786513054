import { useEffect, useRef, useState } from 'react';
import report from '../report.png'
import { url } from "./settings"

export default function MainPage({context,geoPrices,vin,setVin,email,setEmail,toPay,toLogin}) {
    const ref = useRef()
    const refPay = useRef()
    const price = geoPrices.prices[0][1]
    const [course, setCourse] = useState(41.32)

    useEffect(()=>{
        fetch(url + '?action=getExchangeRate')
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setCourse(r.data)
            }
        })
    },[])

    const isValidVin = (vin) => {
        if (vin.length != 17) {return false;}
        const allowedChars = '0123456789ABCDEFGHJKLMNPRSTUVWXYZ';
        let vinArray = vin.split('');
        for (let i = 0; i < vinArray.length; i++) {
          if (allowedChars.indexOf(vinArray[i]) == -1) {
            return false;
          }
        }
        return true;
    }
    const [valid,setValid] = useState(vin ? isValidVin(vin) : false)

    const handleVinSubmit = (e) => {
        e.preventDefault()
        if (ref.current.reportValidity()) {
            let formData = new FormData(ref.current)
            let vin = formData.get('vin').toUpperCase()
            setValid(isValidVin(vin))
            setVin(vin)
        }
    }
    const handlePaySubmit = (e) => {
        e.preventDefault()
        if (refPay.current.reportValidity()) {
            let formData = new FormData(refPay.current)
            setEmail(formData.get('email'))
            toPay()
        }
    }

    return(<>
        <section id="check">
            <div className="container">
                <h2>{context.check}</h2>
                <form ref={ref} onSubmit={handleVinSubmit}>
                    <div className="input-wrapper">
                        <input type="text" className="form-control" placeholder={context.findVin} name="vin" defaultValue={vin} required="" />
                        <span>{context.findVin}</span>
                    </div>  
                    <button className="btn">{context.search}</button> 
                </form>  
            </div>
        </section>
        {vin && <section id="result">
            <div className="container">
                <div className="result__left">
                    <h2>{context.checkResult}</h2>
                    {!valid ? <p>{context.checkResultFalse1}<br/>
                        {context.checkResultFalse2}</p>
                     : <p>{context.checkResultTrue}</p>}
                </div>
                {valid && <div className="result__right">
                    <h2>{context.orderContacts}</h2> 
                    <p>{context.orderDescription}</p>  
                    <form className='stats-block' ref={refPay} onSubmit={handlePaySubmit}>
                        <div className="input-wrapper">
                            <input type="email" className="form-control" placeholder={context.login} defaultValue={email} name="email" required />
                            <span>{context.login}</span>
                        </div>
                        <p className='body_s text300'>{context.orderWarning}</p> 
                        <div className="price">{context.orderPriceText} - {price}$</div>
                        <div className="report">{context.orderFor} - {vin}</div>
                        <button className="btn">{context.paymentsBtn}</button>
                    </form> 
                </div>}
            </div>
        </section>}
        <section id="opt">
            <div className="container">
                <h2>{context.optTitle}</h2>
                <p className='text600'>{context.optMotivation}</p>
                <div className="opt__cards">
                    <div className="stats-block">
                        <h2 className="stats__title">10 {context.optOrders}</h2>
                        <h1>{(29 * course).toFixed(2)} UAH ($29)</h1>
                        <h3>
                            <div className='body_s through'>$3/{context.optUnit}</div>
                            <div className='new'>{(2.9 * course).toFixed(2)} UAH ($2.9)/{context.optUnit}</div>
                        </h3>
                        <button className="btn" onClick={toLogin}>{context.optLogin}</button>
                    </div>
                    <div className="stats-block">
                        <h2 className="stats__title">20 {context.optOrders}</h2>
                        <h1>{(55 * course).toFixed(2)} UAH ($55)</h1>
                        <h3>
                            <div className='body_s through'>$3/{context.optUnit}</div>
                            <div className='new'>{(2.75 * course).toFixed(2)} UAH ($2.75)/{context.optUnit}</div>
                        </h3>
                        <button className="btn" onClick={toLogin}>{context.optLogin}</button>
                    </div>
                    <div className="stats-block">
                        <h2 className="stats__title">50 {context.optOrders}</h2>
                        <h1>{(125 * course).toFixed(2)} UAH ($125)</h1>
                        <h3>
                            <div className='body_s through'>$3/{context.optUnit}</div>
                            <div className='new'>{(2.5 * course).toFixed(2)} UAH ($2.5)/{context.optUnit}</div>
                        </h3>
                        <button className="btn" onClick={toLogin}>{context.optLogin}</button>
                    </div>
                    <div className="stats-block">
                        <h2 className="stats__title">100 {context.optOrders}</h2>
                        <h1>{(225 * course).toFixed(2)} UAH ($225)</h1>
                        <h3>
                            <div className='body_s through'>$3/{context.optUnit}</div>
                            <div className='new'>{(2.25 * course).toFixed(2)} UAH ($2.25)/{context.optUnit}</div>
                        </h3>
                        <button className="btn" onClick={toLogin}>{context.optLogin}</button>
                    </div>
                </div>
            </div>
        </section>
        <section id="faq">
            <div className="container">
                <h2>{context.faqTitle}</h2>
                <p>{context.faqListTitle}:</p>
                <ul>
                    <li>{context.faqListItem1}</li>
                    <li>{context.faqListItem2}</li>
                    <li>{context.faqListItem3}</li>
                    <li>{context.faqListItem4}</li>
                    <li>{context.faqListItem5}</li>
                    <li>{context.faqListItem6}</li>
                </ul>
                <p>{context.faqEnding}</p>
            </div>
        </section>
        <section id="example">
            <div className="container">
                <h2>{context.exampleTitle}</h2>
                <div className="example__grid">
                    <div className="example__left">
                        <p>{context.exampleListTitle}:</p>
                        <ul>
                            <li><b>{context.exampleListItem1Title}:</b> {context.exampleListItem1}</li>
                            <li><b>{context.exampleListItem2Title}:</b> {context.exampleListItem2}</li>
                            <li><b>{context.exampleListItem3Title}:</b> {context.exampleListItem3}</li>
                            <li><b>{context.exampleListItem4Title}:</b> {context.exampleListItem4}</li>
                            <li><b>{context.exampleListItem5Title}:</b> {context.exampleListItem5}</li>
                            <li><b>{context.exampleListItem6Title}:</b> {context.exampleListItem6}</li>
                            <li><b>{context.exampleListItem7Title}:</b> {context.exampleListItem7}</li>
                        </ul>
                        <p>{context.exampleEnding}</p>
                    </div>
                    <div className="example__right">
                        <img src={report} alt="CarFax example" />
                    </div>
                </div>
            </div>
        </section>
        <section id="tech">
            <div className="container">
                <div className="tech__grid">
                    <div className="tech__block">
                        <h2>{context.tech1Title}</h2>
                        <p>{context.tech1Text}</p>
                    </div>
                    <div className="tech__block">
                        <h2>{context.tech2Title}</h2>
                        <p>{context.tech2Text}</p>
                    </div>
                    <div className="tech__block">
                        <h2>{context.tech3Title}</h2>
                        <p>{context.tech3Text}</p>
                        <p>{context.tech3Text2}</p>
                    </div>
                    <div className="tech__block">
                        <h2>{context.tech4Title}</h2>
                        <p>{context.tech4Text}</p>
                        <p>{context.tech4Text2}</p>
                    </div>
                </div>
            </div>
        </section>
    </>)
}