import { useEffect } from "react"
import { useState, useRef } from "react"
import { url } from '../components/settings'


export default function Admin({context}) {
    const [submit,setSubmit] = useState(null)
    const [users,setUsers] = useState([])
    const [filteredUsers,setFilteredUsers] = useState([])
    const [settings,setSettings] = useState({status: 1, banner_link: '', banner_title: '', banner_text: '', banner_status: 0, api: 0})
    const [page,setPage] = useState(1)
    const ref = useRef()
    const [items,setItems] = useState([])
    const deleteFormField = (idx) => {
        let newItems = [...items].filter((f,i) => i != idx)
        setItems(newItems)
    }
    const setStateField = (field,value) => {
        let newItems = [...items]
        newItems[field]['value'] = value
        setItems(newItems)
    }
    const addInput = (type) => {
        let newItems = [...items]
        newItems.push({type, value: ''})
        setItems(newItems)
    }
    const hundleNewsSubmit = () => {   
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        formData.append('data',JSON.stringify(items))
        fetch(url + '?action=sendNews', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(data => {
            if (data['is_ok']) {
                setItems([])
            }
        })
    }

    const getUsers = () => {
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        fetch(url + '?action=getUsers', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setUsers(r.data)
                setFilteredUsers(r.data)
            }
        })
    }
    const getSettings = () => {
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        fetch(url + '?action=getSettings', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                setSettings(r.data)
            }
        })
    }
    const changeSettings = (field,value) => {
        const newSettings = Object.assign({}, settings)
        newSettings[field] = value
        setSettings(newSettings)
    }

    useEffect(()=>{
        getUsers()
        getSettings()
    },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (ref.current.reportValidity()) {
            let pass = ref.current.querySelector('input[name="password"]')
            let validity = true
            if (pass.value.length < 6 || pass.value.length > 32) {
              setSubmit(context.regErrorPass)
              validity = false
            }
            if (validity) {
                let formData = new FormData(ref.current)
                formData.append('fast',1)
                fetch(url + '?action=registration', {method: 'POST', body: formData})
                .then(response => response.json())
                .then(r => {
                    if (r.result) {
                        ref.current.querySelectorAll('input[type="text"]').forEach(inp => {inp.value = ''})
                        setSubmit()
                        getUsers()
                    } else {
                        setSubmit(context[r.error])
                    }
                })
            }
        }
    }
    const handleSubmit2 = () => {
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        formData.append('status',settings.status)
        formData.append('banner_link',settings.banner_link)
        formData.append('banner_title',settings.banner_title)
        formData.append('banner_text',settings.banner_text)
        formData.append('banner_status',settings.banner_status)
        formData.append('api',settings.api)
        fetch(url + '?action=updateSettings', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                alert('Сохранено')
            }
        })
    }

    const filterUsers = (filter) => {
        const newUsers = [...users]
        if (filter != '') {
            let filtered = newUsers.filter((u) => {return u.email.search(filter) >= 0})
            setFilteredUsers(filtered)
        } else {setFilteredUsers(newUsers)}
    }
    const changeUserField = (index,field,value) => {
        const newUsers2 = [...filteredUsers]
        newUsers2[index][field] = value
        setFilteredUsers(newUsers2)
        let email = newUsers2[index].email

        const newUsers = [...users]
        newUsers.map((u,id) => {
            if (u.email == email) {newUsers[id][field] = value}
        })
        setUsers(newUsers)
    }
    const handleUsersSubmit = () => {
        let formData = new FormData()
        formData.append('token',window.localStorage['token'])
        formData.append('users',JSON.stringify(users))
        fetch(url + '?action=updateUsers', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(r => {
            if (r.result) {
                alert('Сохранено')
            }
        })
    }

    return(<>
        <h1>Панель управления</h1>
        <h2 onClick={(e) => {e.target.classList.toggle('opened')}} className="settings__title">Настройки
            <svg className="expander__icon" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.62461 12.5083C2.72507 12.6064 2.85993 12.6613 3.00036 12.6613C3.14079 12.6613 3.27565 12.6064 3.37611 12.5083L8.99999 7.02613L14.6227 12.5083C14.7232 12.6064 14.8581 12.6613 14.9985 12.6613C15.1389 12.6613 15.2738 12.6064 15.3742 12.5083C15.4232 12.4606 15.4622 12.4035 15.4887 12.3405C15.5153 12.2776 15.529 12.2099 15.529 12.1415C15.529 12.0731 15.5153 12.0055 15.4887 11.9425C15.4622 11.8795 15.4232 11.8225 15.3742 11.7748L9.39261 5.94501C9.28756 5.84262 9.14668 5.78531 8.99999 5.78531C8.85329 5.78531 8.71241 5.84262 8.60736 5.94501L2.62574 11.7748C2.57675 11.8225 2.53781 11.8795 2.51123 11.9425C2.48464 12.0055 2.47095 12.0731 2.47095 12.1415C2.47095 12.2099 2.48464 12.2776 2.51123 12.3405C2.53781 12.4035 2.57675 12.4606 2.62574 12.5083L2.62461 12.5083Z" fill="#181818"></path>
            </svg>
        </h2>
        <div className="settings">
            <h2 style={{marginTop: 40}}>Статус сайта</h2>
            <div className="profile__row">
                <label htmlFor="status1">
                    <input type="radio" name="status" id="status1" value="1" onChange={()=>{changeSettings('status',1)}} checked={settings.status == 1} />
                    Работает
                    <div className="radio"></div>
                </label>
                <label htmlFor="status2">
                    <input type="radio" name="status" id="status2" value="0" onChange={()=>{changeSettings('status',0)}} checked={settings.status == 0} />
                    Техобслуживание
                    <div className="radio"></div>
                </label>
            </div>
            <h2 style={{marginTop: 40}}>API</h2>
            <div className="profile__row">
                <label htmlFor="api1">
                    <input type="radio" name="api" id="api1" value="0" onChange={()=>{changeSettings('api',0)}} checked={settings.api == 0} />
                    Свое
                    <div className="radio"></div>
                </label>
                <label htmlFor="api2">
                    <input type="radio" name="api" id="api2" value="1" onChange={()=>{changeSettings('api',1)}} checked={settings.api == 1} />
                    VinWise
                    <div className="radio"></div>
                </label>
                <label htmlFor="api3">
                    <input type="radio" name="api" id="api3" value="2" onChange={()=>{changeSettings('api',2)}} checked={settings.api == 2} />
                    Dealer VIN
                    <div className="radio"></div>
                </label>
            </div>
            <h2 style={{marginTop: 40}}>Баннер</h2>
            <div className="profile__row" style={{marginBottom: 20}}>
                <label htmlFor="banner_status1">
                    <input type="radio" name="banner_status" id="banner_status1" value="1" onChange={()=>{changeSettings('banner_status',1)}} checked={settings.banner_status == 1} />
                    Включен
                    <div className="radio"></div>
                </label>
                <label htmlFor="banner_status2">
                    <input type="radio" name="banner_status" id="banner_status2" value="0" onChange={()=>{changeSettings('banner_status',0)}} checked={settings.banner_status == 0} />
                    Скрыт
                    <div className="radio"></div>
                </label>
            </div>
            <div className="input-wrapper">
                <input type="text" name="banner_title" id="banner_title" onChange={(e)=>{changeSettings('banner_title',e.target.value)}} value={settings.banner_title} placeholder="Заголовок" required />
                <span>Заголовок</span>
            </div>
            <div className="input-wrapper">
                <input type="text" name="banner_text" id="banner_text" onChange={(e)=>{changeSettings('banner_text',e.target.value)}} value={settings.banner_text} placeholder="Текст" required />
                <span>Текст</span>
            </div>
            <div className="input-wrapper">
                <input type="text" name="banner_link" id="banner_link" onChange={(e)=>{changeSettings('banner_link',e.target.value)}} value={settings.banner_link} placeholder="Ссылка на картинку" required />
                <span>Ссылка на картинку</span>
            </div>
            <button className="btn" type="button" onClick={handleSubmit2} style={{marginTop: 20}}>Сохранить</button>
        </div>
        <h2 onClick={(e) => {e.target.classList.toggle('opened')}} style={{marginTop: 40}} className="settings__title">Рассылка
            <svg className="expander__icon" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.62461 12.5083C2.72507 12.6064 2.85993 12.6613 3.00036 12.6613C3.14079 12.6613 3.27565 12.6064 3.37611 12.5083L8.99999 7.02613L14.6227 12.5083C14.7232 12.6064 14.8581 12.6613 14.9985 12.6613C15.1389 12.6613 15.2738 12.6064 15.3742 12.5083C15.4232 12.4606 15.4622 12.4035 15.4887 12.3405C15.5153 12.2776 15.529 12.2099 15.529 12.1415C15.529 12.0731 15.5153 12.0055 15.4887 11.9425C15.4622 11.8795 15.4232 11.8225 15.3742 11.7748L9.39261 5.94501C9.28756 5.84262 9.14668 5.78531 8.99999 5.78531C8.85329 5.78531 8.71241 5.84262 8.60736 5.94501L2.62574 11.7748C2.57675 11.8225 2.53781 11.8795 2.51123 11.9425C2.48464 12.0055 2.47095 12.0731 2.47095 12.1415C2.47095 12.2099 2.48464 12.2776 2.51123 12.3405C2.53781 12.4035 2.57675 12.4606 2.62574 12.5083L2.62461 12.5083Z" fill="#181818"></path>
            </svg>
        </h2>
        <div className="settings">
            {items.map((elem,idx) => {
                if (elem.type === 'text') return (
                    <div key={idx} className="input-wrapper" style={{paddingRight: 30, marginTop: 20}}>
                        <textarea name={`mes_${idx}`} value={elem.value} rows="10" placeholder="Текст"
                            onChange={(e)=>{setStateField(idx,e.target.value)}}
                        />
                        <button onClick={()=>{deleteFormField(idx)}} className="payment_delete">×</button>
                    </div>
                )
                if (elem.type === 'img') return (
                    <div key={idx} className="input-wrapper" style={{paddingRight: 30, marginTop: 20}}>
                        <input type="text" name={`img_${idx}`} onChange={(e)=>{setStateField(idx,e.target.value)}} placeholder="Фото" required />
                        <span>Фото</span>
                        <button onClick={()=>{deleteFormField(idx)}} className="payment_delete">×</button>
                    </div>
                )
                if (elem.type === 'video') return (
                    <div key={idx} className="input-wrapper" style={{paddingRight: 30, marginTop: 20}}>
                        <input type="text" name={`video_${idx}`} onChange={(e)=>{setStateField(idx,e.target.value)}} placeholder="Видео" required />
                        <span>Видео</span>
                        <button onClick={()=>{deleteFormField(idx)}} className="payment_delete">×</button>
                    </div>
                )
            })}
            <div className="body_s pr-add">
                <button onClick={()=>{addInput('text')}} className=''>Текст</button>
                <button onClick={()=>{addInput('img')}} className=''>Фото</button>
                <button onClick={()=>{addInput('video')}} className=''>Видео</button>
            </div>
            <button onClick={hundleNewsSubmit} style={{marginTop: 20}} className="btn">Отправить</button>
        </div>
        <h2 style={{marginTop: 40}}>Создать нового пользователя</h2>
        <div className={`submit-results body_s error ${submit ? '' : 'hidden'}`}>{submit}</div>
        <form ref={ref} onSubmit={handleSubmit}>
            <div className="profile__row">
                <div className="input-wrapper">
                    <input type="text" name="name" id="name" placeholder="Имя" required />
                    <span>Имя</span>
                </div>
                <div className="input-wrapper">
                    <input type="email" name="email" id="email" placeholder="Почта" required />
                    <span>Почта</span>
                </div>
                <div className="input-wrapper">
                    <input type="text" name="password" id="password" placeholder="Пароль" required />
                    <span>Пароль</span>
                </div>
            </div>
            <button className="btn" type="submit">Отправить</button>
        </form>
        <h2 style={{marginTop: 40}}>Список пользователей</h2>
        <div className="input-wrapper">
            <input type="email" name="email" id="email" placeholder="Почта" onInput={(e) => {filterUsers(e.target.value)}} required />
            <span>Почта</span>
        </div>
        <div className="grid">
            <div className="profile__row user body_s">
                <div>Email</div>
                <div>Статус</div>
                <div>Баланс</div>
                <div>Отчеты</div>
                <div>Доступный перелимит</div>
            </div>
        {filteredUsers.map((u,idx) => {
            if (Math.ceil((idx + 1) / 100) == page) return(
            <div key={u.id} className="profile__row user">
                <div className="profile__email">
                    {u.password && <div className="show_pass" onClick={()=>{alert(u.password)}}>🔒</div>}
                    {u.email}
                </div>
                <div><select className="input" name="status" id="status" value={u.status} onChange={(e)=>{changeUserField(idx,'status',e.target.value)}}>
                    <option value="-1">Удалить</option>
                    <option value="0">Не верифицирован</option>
                    <option value="1">Активный</option>
                    <option value="2">Админ</option>
                </select></div>
                <div><input type="number" name="deposit" id="deposit" min={0} value={u.deposit} onChange={(e)=>{changeUserField(idx,'deposit',e.target.value)}} /></div>
                <div><input type="number" name="reports" id="reports" min={0} value={u.reports} onChange={(e)=>{changeUserField(idx,'reports',e.target.value)}} /></div>
                <div><input type="number" name="overdraft" id="overdraft" min={0} value={u.overdraft} onChange={(e)=>{changeUserField(idx,'overdraft',e.target.value)}} /></div>
            </div>
        )})}
        {filteredUsers.length > 100 && <>
            <div style={{display:'inline-flex', marginTop:25, flexWrap:'wrap'}}>
            {Array.from({ length: Math.ceil(filteredUsers.length / 100) }, (_, index) => index + 1).map((pageNumber) => (
                <div className={"wb-page" + (page == pageNumber ? " active" : "")}
                    key={pageNumber}
                    onClick={() => setPage(pageNumber)}
                >{pageNumber}</div>
            ))}
            </div>
        </>}
        </div>
        <button className="btn" type="button" onClick={handleUsersSubmit} style={{marginTop: 20}}>Сохранить</button>
    </>)
}