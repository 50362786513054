import React from 'react';
import { url } from './components/settings'
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
function ContextProvider() {
  const base = process.env.PUBLIC_URL
  const [context,SetContext] = useState(null)
  const [geoPrices,SetGeoPrices] = useState(null)
  const [l,setL] = useState('en')
  const [langs,setLangs] = useState([])

  useEffect(() => {
    fetch(base + 'prices.json')
    .then(response => {return(response.json())})
    .then(dt => {
      let prices = dt[0]
      fetch(base + 'context.json?v=1.1.4')
      .then(response => {return(response.json())})
      .then(data => {
        let cntx = data[0]
        setLangs(Object.keys(cntx))
        let lang = window.localStorage['lang']
        let geo = window.localStorage['geo']
        if (lang && geo && cntx[lang]) {
          setL(lang)
          SetGeoPrices(prices[geo] ? prices[geo] : prices[lang])
          SetContext(cntx)
        } else {
          fetch(url + '?action=getIp')
          .then(response => response.json())
          .then(r => {
            if (r.result) {
              let iso = r.iso.toLowerCase()
              if (iso === 'by') {iso = 'ru'}
              if (cntx[iso]) {
                setL(iso)
                SetGeoPrices(prices[iso] ? prices[iso] : prices[lang])
                window.localStorage['lang'] = iso
                window.localStorage['geo'] = iso
              } else {
                SetGeoPrices(prices[l])
                window.localStorage['lang'] = l
                window.localStorage['geo'] = l
              }
            } else {
              SetGeoPrices(prices[l])
              window.localStorage['lang'] = l
              window.localStorage['geo'] = l
            }
          })
          .then(()=>{SetContext(cntx)})
        }
      })
    })
  },[])

  return(
    context && <App context={context[l]} geoPrices={geoPrices} langs={langs} setL={setL} l={l} />
  )
}

root.render(
  <ContextProvider/>
);
